// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Utils$SystemaFr from "../utils/Utils";
import * as Gatsby$SystemaFr from "../utils/Gatsby";
import * as Layout$SystemaFr from "../components/Layout";

import "../assets/css/not_found.css"
;

var component = ReasonReact.statelessComponent("404");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Layout$SystemaFr.make(undefined, "notfound-layout", [React.createElement("div", {
                                    className: "content"
                                  }, React.createElement("div", {
                                        className: "mdl-grid"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                          }), React.createElement("div", {
                                            className: "mdl-components mdl-cell mdl-cell--8-col"
                                          }, React.createElement("p", {
                                                className: "mdl-typography--headline"
                                              }))), React.createElement("div", {
                                        className: "notfound-ribbon mdl-grid"
                                      }, React.createElement("div", {
                                            className: "mdl-cell mdl-cell--2-col mdl-cell--hide-tablet mdl-cell--hide-phone"
                                          }), React.createElement("div", {
                                            className: "mdl-components mdl-cell mdl-cell--8-col",
                                            style: {
                                              display: "block"
                                            }
                                          }, React.createElement("h1", undefined, Utils$SystemaFr.text("404")), React.createElement("h2", undefined, React.createElement("small", undefined, Utils$SystemaFr.text("Page Introuvable"))), React.createElement("p", {
                                                className: "mdl-typography--title"
                                              }, ReasonReact.element(undefined, undefined, Gatsby$SystemaFr.Link.make("/", undefined, undefined, undefined, undefined, "mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect", undefined, undefined, undefined, undefined, [Utils$SystemaFr.text("Revenir à l’Accueil")]))))))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var $$default = ReasonReact.wrapReasonForJs(component, (function (jsProps) {
        return make(jsProps.children);
      }));

export {
  component ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
